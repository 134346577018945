import './App.css';
import {Canvas} from "@react-three/fiber";
import Blob from "./components/Blob";

function App() {
    const containerStyle = {
        width: '100vw',
        height: '100vh',
        background: '#000000',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    };

    // make this h1 animated when it appears on the screen, like a fade in
    const h1Style = {
        fontFamily: 'Tiro Kannada, serif',
        fontStyle: 'normal',
        fontWeight: '100',
        fontSize: '48px',
        lineHeight: '56px',
        textAlign: 'center',
        color: '#FFFFFF',
        marginTop: '100px',
        marginBottom: '100px',
        position: 'absolute',
        zIndex: '1',
        opacity: '0',
        animation: 'fadeIn 2s ease-in-out forwards',
        letterSpacing: '-0.7px',
    };

    const canvasStyle = {
        width: '100vw',
        height: '100vh',
        background: '#000000',
        borderRadius: '10px',
        marginBottom: '100px',
        marginTop: '100px',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
        position: 'relative',
        zIndex: '0',
    };

    return (
        <div className="container" style={containerStyle}>
            <h1 style={h1Style}>
                KatalisTech
            </h1>

            <Canvas
                camera={{ position: [0.0, 0.0, 8.0]}}
                style={canvasStyle}
            >
                <Blob />
            </Canvas>
        </div>
    );
}

export default App;
