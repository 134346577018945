import React, {useMemo, useRef} from "react";
import vertexShader from "./vertexShader";
import fragmentShader from "./fragmentShader";
import {useFrame} from "@react-three/fiber";
import {MathUtils} from "three";

const Blob =() => {
    const mesh = useRef();
    const hover = useRef(false);
    const uniforms = useMemo(() => {
        return {
            u_time: { value: 0 },
            u_intensity: { value: 0.3 },
        };
    }, []);
    useFrame((state) => {
        const {clock} = state;
        if (mesh.current) {
            mesh.current.material.uniforms.u_time.value = 0.4 * clock.getElapsedTime();
            mesh.current.material.uniforms.u_intensity.value = MathUtils.lerp(
                mesh.current.material.uniforms.u_intensity.value,
                hover.current ? 1.0 : 0.15,
                0.05
            );

            // if there is a cursor in the scene, we can use it to rotate the mesh
            if (state.mouse) {
                // use the mouse position to make the mesh follow the cursor but only very slightly
                mesh.current.position.x = MathUtils.lerp(
                    mesh.current.position.x,
                    state.mouse.x * 0.8,
                    0.08
                );

                // use the mouse position to make the mesh follow the cursor but only very slightly
                mesh.current.position.y = MathUtils.lerp(
                    mesh.current.position.y,
                    state.mouse.y * 0.8,
                    0.08
                );

                // if the cursor is not in the scene, move the mesh back to the center
                if (!state.mouse.x && !state.mouse.y) {
                    mesh.current.position.x = MathUtils.lerp(
                            0,
                        0,
                        0.1
                    );
                }

            } else if (state.touches[0]) {
                mesh.current.rotation.x = MathUtils.lerp(
                    mesh.current.rotation.x,
                    state.touches[0].y * Math.PI,
                    0.1
                );
                mesh.current.rotation.y = MathUtils.lerp(
                    mesh.current.rotation.y,
                    state.touches[0].x * Math.PI,
                    0.1
                );
            }

            // handle the mobile web rotation
            if (state.gamma) {
                mesh.current.rotation.x = MathUtils.lerp(
                    mesh.current.rotation.x,
                    state.gamma * Math.PI,
                    0.1
                );
                mesh.current.rotation.y = MathUtils.lerp(
                    mesh.current.rotation.y,
                    state.beta * Math.PI,
                    0.1
                );
            }
        }
    });
    return (
        <mesh
            ref={mesh}
            scale={1}
            position={[0,0,0]}
            onPointerOver={()=> {hover.current = true}}
            onPointerOut={()=> {hover.current = false}}
        >
            <icosahedronGeometry args={[2,20]} />
            <shaderMaterial
                vertexShader={vertexShader}
                fragmentShader={fragmentShader}
                uniforms={uniforms}
            />
        </mesh>
    )
}

export default Blob;
